import { createInstance, i18n } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next/initReactI18next";
import { getLanguageData, getOptions } from "./settings";

const initI18next = async (lang: string, ns?: string) => {
    const i18nInstance = createInstance();
    await i18nInstance
        .use(initReactI18next)
        .use(resourcesToBackend((language: string, namespace: string) => import(`@/translations/${language}/${namespace}.json`)))
        .init(getOptions(lang, ns));
    return i18nInstance;
};

function getResourceKey(i18nextInstance: i18n, lang: string, ns?: string) {
    return function getResourceKey<T>(key: string) {
        return i18nextInstance.getResource(lang, ns ?? "", key) as T;
    };
}

// TODO: is this ok or can we use more explicit type?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function translateOnServer(lang: string, ns?: string, options: any = {}) {
    const i18nextInstance = await initI18next(getLanguageData(lang).translation, ns);
    // const namespace = Array.isArray(ns) ? ns[0] : ns as string;
    return {
        t: i18nextInstance.getFixedT(lang, ns, options["keyPrefix"]),
        tTyped: getResourceKey(i18nextInstance, lang, ns),
        i18n: i18nextInstance
    };
}
