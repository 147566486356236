import { format } from "date-fns";
import { getLanguageData } from "../i18n/settings";
import { InputState } from "../components/Input";
import { useMemo } from "react";
import { getCountries } from "libphonenumber-js";
import { DetailedErrorResponse, ValidationResponse } from "../components/UserProfile/Security/types";
import { translateOnServer } from "../i18n";
import { Metadata } from "next";
import { SITES } from "@/sites";


export function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(" ");
}

export const getFieldStatus = (isTouched: boolean, hasErrors: boolean): InputState => {
    if (isTouched && hasErrors) {
        return "error";
    }
    if (isTouched && !hasErrors) {
        return "valid";
    }
    return "none";
};

export const showFieldError = (
    isFormDirty: boolean,
    error: string | undefined,
    touched: boolean | undefined,
): boolean => {
    return (isFormDirty || (touched && !!error)) ?? false;
};

export const formatDateFmt = (lang: string, dateString: string, fmt: string) => {
    const locale = getLanguageData(lang);
    const dateObject = new Date(dateString);
    const result = format(dateObject, fmt, { locale: locale.dateLocale });
    return result;
};

export const formatDate = (lang: string, dateString: string): string => {
    return formatDateFmt(lang, dateString, "d.M.yyyy");
};

export const formatDateShortMonth = (lang: string, dateString: string): string => {
    return formatDateFmt(lang, dateString, "d. LLL. yyyy");
};

export const formatDateTimeShortMonth = (lang: string, dateString: string): string => {
    return formatDateFmt(lang, dateString, "d. LLL. yyyy, H:mm");
};

export const formatTime = (lang: string, dateString: string): string => {
    return formatDateFmt(lang, dateString, "h:mm");
};

export const formatDateName = (lang: string, dateString: string): string => {
    return formatDateFmt(lang, dateString, "MMMM d, yyyy h:mm a");
};

export const handleServerError = (
    error: DetailedErrorResponse,
    handleMessage: (message: null | [string] | Array<string>) => void,
) => {
    const detailedError = error;
    if (detailedError.cause) {
        const axiosErr = detailedError.cause;
        if (axiosErr?.response?.data) {
            const axiosResponse = axiosErr.response;
            if (axiosResponse?.data && Object.hasOwn(axiosResponse.data, "validation")) {
                const validation = (axiosResponse.data as DetailedErrorResponse)?.validation as ValidationResponse;
                handleMessage([validation.body.message]);
            } else {
                const errorMessages: Array<string> = [
                    detailedError.cause.message,
                    (axiosErr.response.data as { message: string }).message,
                ];
                handleMessage(errorMessages);
            }
        }
    } else {
        handleMessage([detailedError.message ?? "An unknown error occurred"]);
    }
};

export const formatNumber = (lang: string, value: number, precision?: number) => {
    const multiplier = Math.pow(10, precision ?? 0);
    return (Math.round(value * multiplier) / multiplier).toLocaleString(lang);
};

export const GetCountries = (lang: string): Record<string, string>[] => {
    const regionNames = useMemo(() => new Intl.DisplayNames([lang], { type: "region" }), [lang]);
    const countries: Record<string, string>[] = getCountries().map((country) => {
        const item = {
            key: country,
            value: regionNames.of(country) || "",
        };
        return item;
    });

    return countries;
};

export const getMetadataDefaults = async (lang: string) => {
    const { t } = await translateOnServer(lang);

    const metadata = {
        metadata: {
            title: t("title"),
            description: t("description"),
            openGraph: {
                images: ["https://res.cloudinary.com/mr24/image/upload/bo_11px_solid_rgb:ffffff00,c_pad,h_627,w_1200/v1691660305/test/static/logos/myroom24-logo-colored-orange.jpg"],
            },
            icons: {
                icon: [
                    {
                        url: "/favicon-16x16.ico",
                        type: "image/ico",
                        sizes: "16x16",
                    },
                    {
                        url: "/favicon-16x16.png",
                        type: "image/png",
                        sizes: "16x16",
                    },
                    {
                        url: "/favicon-32x32.ico",
                        type: "image/ico",
                        sizes: "32x32",
                    },
                    {
                        url: "/favicon-32x32.png",
                        type: "image/png",
                        sizes: "32x32",
                    },
                    {
                        url: "/favicon-96x96.ico",
                        type: "image/ico",
                        sizes: "96x96",
                    },
                    {
                        url: "/favicon-96x96.png",
                        type: "image/png",
                        sizes: "96x96",
                    },
                    {
                        url: "/android-icon-192x192.png",
                        type: "image/png",
                        sizes: "192x192",
                    },
                ],
                apple: [57, 60, 72, 76, 114, 120, 144, 152, 180].map(size => {
                    return {
                        url: `/apple-touch-icon-${size}x${size}.png`,
                        type: "image/png",
                        sizes: `${size}x${size}`,
                    };
                }),
            }
        } as Metadata,
        t: t
    };

    const googleVerification = getGoogleVerification(lang);
    if (googleVerification) {
        metadata.metadata.verification = {
            google: googleVerification
        };
    }

    return metadata;
};

export const getGTMCode = (lang: string) => {
    if (Object.keys(SITES.gtmCode).includes(lang) && SITES.gtmCode[lang]) {
        return SITES.gtmCode[lang];
    }
    return process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER;
};

export const getGoogleVerification = (lang: string) => {
    if (Object.keys(SITES.googleVerification).includes(lang) && SITES.googleVerification[lang]) {
        return SITES.googleVerification[lang];
    }
    return process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION;
};
