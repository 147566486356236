import { de, enGB, fr, pt, it, es, tr, zhCN, ru } from "date-fns/locale";


export const fallbackLng = "en";

export type LanguageSettings = {
    name: string;
    localName: string; // name of language in own language
    flagIcon: string;
    currency: string;
    currencySign?: string;
    translation: string;
    dateLocale: Locale; // used in date-fns, use from a list here: https://github.com/date-fns/date-fns/tree/main/src/locale DONT'T FORGET TO IMPORT AT THE TOP
}

export const languageData: Record<string, LanguageSettings> = {
    "en": {
        "name": "English",
        "localName": "English",
        "flagIcon": "gb",
        "currency": "EUR",
        "currencySign": "€",
        "translation": "en",
        "dateLocale": enGB,
    },
    "de": {
        "name": "German",
        "localName": "Deutsch",
        "flagIcon": "de",
        "currency": "EUR",
        "currencySign": "€",
        "translation": "de",
        "dateLocale": de,
    },
    "fr": {
        "name": "French",
        "localName": "Française",
        "flagIcon": "fr",
        "currency": "EUR",
        "currencySign": "€",
        "translation": "fr",
        "dateLocale": fr,
    },
    "pt": {
        "name": "Portuguese",
        "localName": "Português",
        "flagIcon": "pt",
        "currency": "EUR",
        "currencySign": "€",
        "translation": "pt",
        "dateLocale": pt,
    },
    "it": {
        "name": "Italian",
        "localName": "Italiano",
        "flagIcon": "it",
        "currency": "EUR",
        "currencySign": "€",
        "translation": "it",
        "dateLocale": it,
    },
    "es": {
        "name": "Spanish",
        "localName": "Español",
        "flagIcon": "es",
        "currency": "EUR",
        "currencySign": "€",
        "translation": "es",
        "dateLocale": es,
    },
    "tr": {
        "name": "Turkish",
        "localName": "Türkçe",
        "flagIcon": "tr",
        "currency": "TRY",
        "currencySign": "₺",
        "translation": "tr",
        "dateLocale": tr,
    },
    "cn": {
        "name": "Chinese",
        "localName": "普通话", // mandarin
        "flagIcon": "cn",
        "currency": "CNY",
        "currencySign": "¥",
        "translation": "cn",
        "dateLocale": zhCN,
    },
    "ru": {
        "name": "Russian",
        "localName": "Русский язык",
        "flagIcon": "ru",
        "currency": "RUB",
        "currencySign": "₽",
        "translation": "ru",
        "dateLocale": ru,
    }
};

export const languages = [...Object.keys(languageData)] as const;

export const defaultNS = "translation";

type LanguagesValue = typeof languages;
export type Language = LanguagesValue[number];

export function getOptions(lng = fallbackLng, ns = defaultNS) {
    return {
        // debug: true,
        supportedLngs: languages,
        fallbackLng,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        ns,
    };
}

export function getLanguageData(lang: Language) {
    if (lang in languageData) {
        return languageData[lang];
    }
    return languageData[fallbackLng];
}
