"use client";

import i18next from "i18next";
import {
    initReactI18next,
    useTranslation as useTranslationOrg,
} from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {
    Language,
    defaultNS,
    fallbackLng,
    getOptions,
    languageData,
    languages,
} from "./settings";
import { useEffect, useState } from "react";
import { setDefaultOptions } from "date-fns";
import { useLanguage } from "../hooks/useLanguage";


const runsOnServerSide = typeof window === "undefined";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(
        resourcesToBackend(
            (language: string, namespace: string) =>
                import(`@/translations/${language}/${namespace}.json`),
        ),
    )
    .init({
        ...getOptions(),
        lng: undefined, // let detect the language on client side
        detection: {
            order: ["path", "htmlTag", "cookie", "navigator"],
        },
        preload: runsOnServerSide ? languages : [],
    });

export function useTranslation(
    lng: Language,
    ns: string = defaultNS,
    options?: object,
) {
    const ret = useTranslationOrg(ns, options);
    const { i18n } = ret;

    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

    useEffect(() => {
        if (!runsOnServerSide || !lng || i18n.resolvedLanguage === lng) {
            if (activeLng === i18n.resolvedLanguage) return;
            setActiveLng(i18n.resolvedLanguage);
        }
    }, [activeLng, i18n.resolvedLanguage, lng]);

    useEffect(() => {
        if (!runsOnServerSide || !lng || i18n.resolvedLanguage === lng) {
            if (!lng || i18n.resolvedLanguage === lng) return;
            i18n.changeLanguage(lng);
        }
    }, [lng, i18n]);

    if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
        i18n.changeLanguage(lng);
    }
    return ret;
}

export function useDateFnsLocale() {
    const lang = useLanguage();
    if (lang && lang in languageData) {
        setDefaultOptions({ locale: languageData[lang].dateLocale });
        return languageData[lang].dateLocale;
    }
    return languageData[fallbackLng].dateLocale;
}

export function translateISOCountry(isoCode: string, lang: string) {
    const getCountryNames = new Intl.DisplayNames([lang], { type: "region" });
    try {
        return getCountryNames.of(isoCode.toUpperCase()) ?? isoCode;
    } catch {
        return isoCode;
    }
}
