import { classNames } from "@/lib/utils/general";
import React from "react";

export type TextProps = {
    as?: "span" | "p" | "h1" | "h1-seo" | "h2" | "h3" | "h4" | "h5" | "h6";
    useCustomTextSize?: boolean;
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
>;

export default function Text({ as, className, useCustomTextSize = false, ...rest }: TextProps) {
    const cns = className || "";
    let component = <span className={`${cns}`} {...rest}></span>;
    if (as) {
        switch (as) {
            case "p":
                component = <p className={cns} {...rest}></p>;
                break;
            case "h1":
                component = <h1 className={classNames(!useCustomTextSize && "text-5xl", cns)} {...rest}></h1>;
                break;
            case "h1-seo":
                component = <div className={classNames(!useCustomTextSize && "text-5xl", cns)}><h1 {...rest}></h1></div>;
                break;
            case "h2":
                component = <h2 className={classNames(!useCustomTextSize && "text-4xl", cns)} {...rest}></h2>;
                break;
            case "h3":
                component = <h3 className={classNames(!useCustomTextSize && "text-3xl", cns)} {...rest}></h3>;
                break;
            case "h4":
                component = <h4 className={classNames(!useCustomTextSize && "text-2xl", cns)} {...rest}></h4>;
                break;
            case "h5":
                component = <h5 className={classNames(!useCustomTextSize && "text-xl", cns)} {...rest}></h5>;
                break;
            case "h6":
                component = <h6 className={classNames(!useCustomTextSize && "text-lg", cns)} {...rest}></h6>;
                break;
        }
    }

    return component;
}
