"use client";

import { usePathname } from "next/navigation";
import { Language, fallbackLng, languages } from "../i18n/settings";
import { getLangFromHost } from "@/sites";

export function getLanguageFromPath(path: string): Language {
    const items = path.split("/");
    const langIdx = items.findIndex(item => !!item);
    const getFallback = () => {
        if (typeof window !== "undefined") {
            return getLangFromHost(`${window.location.protocol}//${window.location.host}`) ?? fallbackLng;
        }
        return fallbackLng;
    };
    if (langIdx !== -1 && languages.includes(items[langIdx])) {
        return items[langIdx];
    }
    return getFallback();
}

export function useLanguage(): Language {
    const pathName = usePathname();
    return getLanguageFromPath(pathName);
}
